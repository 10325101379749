<template>
    <div class="flex center">
        <div class="col8 flex center column">
            <div class="flex center">
                <div class="title2"> 信息公示 </div>
            </div>
            <div class=" flex center wrap">
                <div class=" wow rollIn" style="text-align:center;">
                    <div class="" style="margin-bottom: 50px;margin-top: 20px">

                      <h2>
                        关于福建历思检测技术有限公司接受多部门监督的公示
                      </h2>

                      福建历思检测技术有限公司自觉接受市场监管、公安、环保等部门的监督。市场监管投诉举报电话：12315


                    </div>

                </div>

            </div>
            <!-- <div class="flex center">
                <div class="title2">鉴定实力</div>
            </div> -->
            <!-- <div class="flex wrap">
                <div class="col5 inline_block wow rotateInUpRight"  v-for="item in data" :key="item.url">
                    <div class="strength col9" style="margin:10px auto;">
                        <img style="width:100%;height:300px"  v-lazy="require('../../assets/about/introduce/'+item.url)">
                        <div style="background:#555555;padding:20px 10px;width:100%;height:fit-content;box-sizing:border-box">
                            <p class="titlee">{{item.title}}</p>
                            <p class="content">{{item.content}}</p>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            data:[
                {url:'1-1.png',title:'√权威鉴定',content:'福建历思司法鉴定所是国内禁毒领域领先的司法鉴定机构，在血液、尿液、毛发、成品、原料、环境的毒检方面均有深入研究；近年来承担了福建省大量涉毒案件鉴定工作，与省内各地市警方就制贩毒、查吸、在册人员管控、毒情监测分析、毒情逆向追踪等展开系统性合作，并长期接收来自海南、江苏、湖南、湖北、河北、河南、浙江、江西、山东、山西、广东、广西等全国各地的涉毒案件'},
                {url:'1-2.png',title:'√先进设备',content:'实验室洁净区达到万级标准，全面配置Waters、Thermo Fisher、AB公司、foster + freeman、基恩士、奥林巴斯等生产的最新型进口液相、液质联用、ICP-MS、离子色谱、基因测序仪、超快速拉曼成像光谱仪、声像工作站、超景深三维立体显微系统（福建省首套）、等高端检验设备，走在了行业前沿。'},
                {url:'1-3.jpg',title:'√专家团队',content:'历思与西南政法大学、厦门大学、集美大学、福建技术师范学院等多所高校和研究所开展了形式多样的合作。共建了物证联合研究中心、联合实验室与教学科研实践基地。历思是西南政法大学教学科研实践基地。历思与西政已就联合技术研究、重大敏感案件讨论、专家互访、学生实习等实现常态化交流。2020年12月，历思与西南政法大学签订了“成渝地区双城经济圈建设”科技创新项目合同。'},
                {url:'1-4.jpg',title:'√质量管理',content:'历思司法鉴定所始终把诚信客观放在第一位，认真执行《司法鉴定程序通则》，严格遵循《司法鉴定/法庭科学机构能力认可准则》、《检验检测机构资质认定管理办法》和《检验检测机构资质认定评审准则》，围绕质量方针和目标的实现，完善管理体系，以科学、客观、独立、公正为实施鉴定的原则，以提供优质、高效的鉴定服务，保障司法与仲裁活动的顺利进行为宗旨；以严格遵守法律法规和规章制度为执业的原则；以遵守职业道德和执业纪律为公平竞争的原则；运用先进的科学技术，发扬严肃认真的工作作风，全心全意地为社会各界排忧解难，为国家的经济发展和社会和谐稳定发挥应有的作用。'},
            ]
        }
    }
}
</script>
<style scoped>
.strength .titlee{
    text-align:left;
    margin-left:2rem;
    color:#17E1D7;
}
.strength .content{
    text-indent:1.6rem;
    font-size: 0.8rem;
    text-align:left;
    color:#fff;
}
</style>
