import Vue from 'vue'
import Router from 'vue-router'
import LayoutContent from '@/components/LayoutContent'
Vue.use(Router)

let enterprisesRouter = new Router({
    mode:'history',
    routes:[
        {
            path:'*',
            redirect:'/home',
        },
        {
            path:'/',
            redirect:'/home',
            component:LayoutContent,
            children:[
                {
                    path:'home',
                    component: () => import('./views/Home/home.vue'),
                    meta:{title:'首页'}
                },
                {
                    path:'about',
                    redirect:'/about/introduce',
                    component: () => import('./views/About/index.vue'),
                    meta:{title:'关于历思'},
                    children:[
                        {
                            path:'introduce',
                            component: () => import('./views/About/Introduce.vue'),
                            meta:{title:'机构简介'}
                        },
                        {
                            path:'history',
                            component: () => import('./views/About/history.vue'),
                            meta:{title:'发展历程'}
                        },
                        {
                            path:'honor',
                            component: () => import('./views/About/honor.vue'),
                            meta:{title:'荣誉资质'}
                        },
                        {
                            path:'env',
                            component: () => import('./views/About/env.vue'),
                            meta:{title:'科室环境'}
                        },
                        {
                            path:'public',
                            component: () => import('./views/About/public.vue'),
                            meta:{title:'信息公示'}
                        }
                    ]
                },
                {
                    path:'news',
                    redirect:'/news/lsnews',
                    component: ()=> import('./views/News/index.vue'),
                    meta:{title:'新闻中心'},
                    children:[
                        {
                            path:'lsnews',
                            component: () => import('./views/News/lsnews.vue'),
                            meta:{title:'历思动态'}
                        },
                        {
                            path:'lsnews/1',
                            component: () => import('./views/News/lsnews_1.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/2',
                            component: () => import('./views/News/lsnews_2.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/3',
                            component: () => import('./views/News/lsnews_3.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'industrynews',
                            component: () => import('./views/News/industrynews.vue'),
                            meta:{title:'行业新闻'}
                        },
                        {
                            path:'industrynews/1',
                            component: () => import('./views/News/industrynews_1.vue'),
                            meta:{ignore:true,title:'行业新闻',path:'/news/industrynews'}
                        },
                        {
                            path:'industrynews/2',
                            component: () => import('./views/News/industrynews_2.vue'),
                            meta:{ignore:true,title:'行业新闻',path:'/news/industrynews'}
                        },
                        {
                            path:'law',
                            component: () => import('./views/News/law.vue'),
                            meta:{title:'法律法规'}
                        },
                        {
                            path:'law/1',
                            component: () => import('./views/News/law_1.vue'),
                            meta:{ignore:true,path:'/news/law',title:'法律法规'}
                        },
                        {
                            path:'law/2',
                            component: () => import('./views/News/law_2.vue'),
                            meta:{ignore:true,path:'/news/law',title:'法律法规'}
                        },
                        {
                            path:'law/3',
                            component: () => import('./views/News/law_3.vue'),
                            meta:{ignore:true,path:'/news/law',title:'法律法规'}
                        },

                        // 新增文章
                        {
                            path:'lsnews/4',
                            component: () => import('./views/News/lsnews_4.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/5',
                            component: () => import('./views/News/lsnews_5.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/6',
                            component: () => import('./views/News/lsnews_6.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/7',
                            component: () => import('./views/News/lsnews_7.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/8',
                            component: () => import('./views/News/lsnews_8.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/10',
                            component: () => import('./views/News/lsnews_10.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/11',
                            component: () => import('./views/News/lsnews_11.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/12',
                            component: () => import('./views/News/lsnews_12.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/13',
                            component: () => import('./views/News/lsnews_13.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/14',
                            component: () => import('./views/News/lsnews_14.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/15',
                            component: () => import('./views/News/lsnews_15.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/16',
                            component: () => import('./views/News/lsnews_16.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/17',
                            component: () => import('./views/News/lsnews_17.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/18',
                            component: () => import('./views/News/lsnews_18.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/19',
                            component: () => import('./views/News/lsnews_19.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/20',
                            component: () => import('./views/News/lsnews_20.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/21',
                            component: () => import('./views/News/lsnews_21.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/22',
                            component: () => import('./views/News/lsnews_22.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/23',
                            component: () => import('./views/News/lsnews_23.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/24',
                            component: () => import('./views/News/lsnews_24.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/25',
                            component: () => import('./views/News/lsnews_25.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/26',
                            component: () => import('./views/News/lsnews_26.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/27',
                            component: () => import('./views/News/lsnews_27.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/28',
                            component: () => import('./views/News/lsnews_28.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/29',
                            component: () => import('./views/News/lsnews_29.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/30',
                            component: () => import('./views/News/lsnews_30.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/31',
                            component: () => import('./views/News/lsnews_31.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/32',
                            component: () => import('./views/News/lsnews_32.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/33',
                            component: () => import('./views/News/lsnews_33.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/34',
                            component: () => import('./views/News/lsnews_34.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/35',
                            component: () => import('./views/News/lsnews_35.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/36',
                            component: () => import('./views/News/lsnews_36.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/37',
                            component: () => import('./views/News/lsnews_37.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/38',
                            component: () => import('./views/News/lsnews_38.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                        {
                            path:'lsnews/39',
                            component: () => import('./views/News/lsnews_39.vue'),
                            meta:{ignore:true,title:'历思动态',path:'/news/lsnews'}
                        },
                    ]
                },
                {
                    path:'range',
                    redirect:'range/clinical',
                    component:()=>import("./views/Range/index.vue"),
                    meta:{title:'鉴定范围'},
                    children:[

                        {
                            path:'clinical',
                            component:()=>import("./views/Range/clinical.vue"),
                            meta:{title:'法医临床鉴定'},
                        },
                        {
                            path:'evidence',
                            component:()=>import("./views/Range/evidence.vue"),
                            meta:{title:'法医物证鉴定'},
                        },
                        {
                            path:'poisons',
                            component:()=>import("./views/Range/poisons.vue"),
                            meta:{title:'法医毒物鉴定'},
                        },
                        {
                          path:'pathology',
                          component:()=>import("./views/Range/pathology.vue"),
                          meta:{title:'法医病理鉴定'},
                      },
                        {
                            path:'document',
                            component:()=>import("./views/Range/document.vue"),
                            meta:{title:'文书鉴定'},
                        },
                        {
                            path:'trace',
                            component:()=>import("./views/Range/trace.vue"),
                            meta:{title:'痕迹鉴定'},
                        },
                        {
                            path:'trace_evidence',
                            component:()=>import("./views/Range/trace_evidence.vue"),
                            meta:{title:'微量物证鉴定'},
                        },
                        {
                            path:'voice',
                            component:()=>import("./views/Range/voice.vue"),
                            meta:{title:'录音鉴定'},
                        },
                        {
                            path:'audio_video',
                            component:()=>import("./views/Range/audio_video.vue"),
                            meta:{title:'图像鉴定'},
                        },
                        {
                            path:'electronic',
                            component:()=>import("./views/Range/electronic.vue"),
                            meta:{title:'电子数据鉴定'},
                        },
                        {
                            path:'env',
                            component:()=>import("./views/Range/env.vue"),
                            meta:{title:'环境损害鉴定'},
                        },
                    ]
                },
                {
                    path:'guide',
                    redirect:"/guide/flow",
                    component:() => import('./views/Guide/index.vue'),
                    meta:{title:'鉴定指南'},
                    children:[
                        {
                            path:'flow',
                            component:() => import('./views/Guide/flow.vue'),
                            meta:{title:'鉴定流程'}
                        },
                        {
                            path:'notice',
                            component:() => import('./views/Guide/notice.vue'),
                            meta:{title:'鉴定须知'}
                        },
                        // {
                        //     path:'necessary',
                        //     component:() => import('./views/Guide/necessary.vue'),
                        //     meta:{title:'必备材料'}
                        // },
                        {
                            path:'download',
                            component:() => import('./views/Guide/download.vue'),
                            meta:{title:'文件下载'}
                        },
                        {
                            path:'search',
                            component:() => import('./views/Guide/search.vue'),
                            meta:{title:'案件查询'}
                        },
                    ]
                },
                {
                    path:'case',
                    redirect:"/case/legal_case",
                    component:() => import('./views/Case/index.vue'),
                    meta:{title:'案例论丛'},
                    children:[
                        {
                            path:'legal_case',
                            component:() => import('./views/Case/legal_case.vue'),
                            meta:{title:'法医毒物案例'}
                        },
                        {
                            path:'legal_case/1',
                            component:() => import('./views/Case/legal_case_1.vue'),
                            meta:{ignore:true,title:'法医毒物案例',path:'/case/legal_case'}
                        },
                        {
                            path:'trace_case',
                            component:() => import('./views/Case/trace_case.vue'),
                            meta:{title:'文痕案例'}
                        },
                        {
                            path:'trace_case/1',
                            component:() => import('./views/Case/trace_case_1.vue'),
                            meta:{ignore:true,title:'文痕案例',path:'/case/trace_case'}
                        },
                        {
                            path:'trace_case/2',
                            component:() => import('./views/Case/trace_case_2.vue'),
                            meta:{ignore:true,title:'文痕案例',path:'/case/trace_case'}
                        },
                        {
                            path:'mic_case',
                            component:() => import('./views/Case/mic_case.vue'),
                            meta:{title:'微量案例'}
                        },
                        {
                            path:'mic_case/1',
                            component:() => import('./views/Case/mic_case_1.vue'),
                            meta:{ignore:true,title:'微量案例',path:'/case/video_case'}
                        },
                        {
                            path:'video_case',
                            component:() => import('./views/Case/video_case.vue'),
                            meta:{title:'声像资料案例'}
                        },
                        {
                            path:'video_case/1',
                            component:() => import('./views/Case/video_case_1.vue'),
                            meta:{ignore:true,title:'声像资料案例',path:'/case/video_case'}
                        },
                        {
                            path:'env_case',
                            component:() => import('./views/Case/env_case.vue'),
                            meta:{title:'环境案例'}
                        },
                        {
                            path:'env_case/1',
                            component:() => import('./views/Case/env_case_1.vue'),
                            meta:{ignore:true,title:'环境案例',path:'/case/env_case'}
                        },
                        {
                            path:'env_case/2',
                            component:() => import('./views/Case/env_case_2.vue'),
                            meta:{ignore:true,title:'环境案例',path:'/case/env_case'}
                        },
                        {
                            path:'env_case/3',
                            component:() => import('./views/Case/env_case_3.vue'),
                            meta:{ignore:true,title:'环境案例',path:'/case/env_case'}
                        },
                    ]
                },
                {
                    path:'manpower',
                    redirect:"/manpower/man_idea",
                    component:() => import('./views/Manpower/index.vue'),
                    meta:{title:'人力资源'},
                    children:[
                        // {
                        //     path:'man_idea',
                        //     component:() => import('./views/Manpower/man_idea.vue'),
                        //     meta:{title:'员工意识'},
                        // },
                        {
                            path:'invite',
                            component:() => import('./views/Manpower/invite.vue'),
                            meta:{title:'人才招聘'},
                        },
                        {
                            path:'show',
                            component:() => import('./views/Manpower/show.vue'),
                            meta:{title:'员工风采'},
                        }
                    ]
                },
                {
                    path:'connect',
                    redirect:"/connect/connect_way",
                    component:() => import('./views/Connect/index.vue'),
                    meta:{title:'联系我们'},
                    children:[
                        {
                            path:'connect_way',
                            component:() => import('./views/Connect/connect_way.vue'),
                            meta:{title:'联系方式'},
                        },
                        {
                            path:'inline_connect',
                            component:() => import('./views/Connect/inline_connect.vue'),
                            meta:{title:'在线留言'},
                        },
                    ]
                }
            ]
        },

    ],
    scrollBehavior () {
        // return 期望滚动到哪个的位置
        return {x:0,y:0}
      }
})
enterprisesRouter.beforeEach((to,from,next)=>{
    console.log(to,from)
    if(to.fullPath==from.fullPath){
        next(false)
    }else{
        next()
    }
})
export default enterprisesRouter
